import React, { useState, useEffect, useRef } from 'react';
import '@mdxeditor/editor/style.css'
import { MDXEditor, headingsPlugin, toolbarPlugin, UndoRedo, BoldItalicUnderlineToggles, BlockTypeSelect, CreateLink, linkDialogPlugin, InsertTable, tablePlugin, InsertThematicBreak, thematicBreakPlugin, ListsToggle, listsPlugin, imagePlugin} from '@mdxeditor/editor'

export function AiCanvas() {
  const [content, setContent] = useState('## Hello, MDX Editor!\nStart editing here...');
  const [selectedText, setSelectedText] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [note, setNote] = useState('');
  const editorRef = useRef(null);

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString()) {
      setSelectedText(selection.toString());

      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setMenuPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  };

  const makeShorter = () => {
    alert(`Make shorter action for: "${selectedText}"`);
    setShowMenu(false);
  };

  const expand = () => {
    alert(`Expand action for: "${selectedText}"`);
    setShowMenu(false);
  };

  const handleGoButton = () => {
    alert(`Note submitted for "${selectedText}": ${note}`);
    setNote('');
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    return () => document.removeEventListener('mouseup', handleTextSelection);
  }, []);

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', position: 'relative' }}>
      <MDXEditor markdown={'# Hello World'} 
      plugins={[
        headingsPlugin(),
        toolbarPlugin({
          toolbarClassName: 'my-classname',
          toolbarContents: () => (
            <>
              {' '}
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <BlockTypeSelect />
              <CreateLink />
              <InsertTable /> 
              <InsertThematicBreak /> 
              <ListsToggle />
            </>
          )
        }),
        tablePlugin(),
        imagePlugin(),
        linkDialogPlugin(),
        thematicBreakPlugin(),
        listsPlugin()
      ]}
       />

      {showMenu && (
        <div
          style={{
            position: 'absolute',
            top: menuPosition.top,
            left: menuPosition.left,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
          }}
        >
          <button onClick={makeShorter}>Make Shorter</button>
          <button onClick={expand}>Expand</button>
          <div style={{ marginTop: '10px' }}>
            <input
              type="text"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Type notes here..."
              style={{ marginRight: '5px' }}
            />
            <button onClick={handleGoButton}>Go</button>
          </div>
        </div>
      )}
    </div>
  );
}

