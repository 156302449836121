import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Card, useAPI, Grid, Button, ViewContext, Badge, Form, AuthContext } from 'components/lib';
import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import Markdown from 'markdown-to-jsx'

export function App_connection(props){

  const [connections, setConnections] = useState([]);
  const [showLib, setShowLib] = useState(true);

  const list = useAPI('/api/app_connection');
  const user = useAPI('/api/user');

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext)

  const [showSettingsPanel, setShowSettingsPanel] = useState(null);
  const [activeSettings, setActiveSettings] = useState(null);
  const [disconnectConfirm, setDisconnectConfirm] = useState(null);

  const appCategories = [
    {
      name: "Data Apps",
      code: "data"
    },
    {
      name: "Notifier Apps",
      code: "notification"
    },
    {
      name: "Practice Management",
      code: "pm"
    },
    {
      name: "CRM Apps",
      code: "crm"
    },
  ]

  const apps = [
    {
      name: 'QuickBooks',
      short_name: 'qbo',
      type: 'data',
      icon: '/images/qb_thumb.png',
      coming_soon: false,
      prefix: 'Company',
      description: "Connecting QuickBooks Online enables real-time data monitoring. LedgerView will begin by retrieving the last 30 days of transaction details and process each of them. It will flag all rules violations and show them in the LedgerView dashboard. Once completed, LedgerView will be alerted to any changes within QuickBooks and, in real-time, will process these changes.\n\nLedgerView will also retrieve and process all list data, including\n\n* Customers\n* Vendors\n* Employees\n* Products\n* Chart of Accounts\n"
    },
    {
      name: 'Xero',
      short_name: 'xero',
      type: 'data',
      icon: '/images/xero.svg',
      coming_soon: false
    },
    {
      name: 'Stripe',
      short_name: 'stripe',
      type: 'data',
      icon: '/images/stripe.png',
      coming_soon: false,
      edit_settings: true,
      settings: {
        title: 'Setup Connection',
        buttonText: 'Connect',
        url: undefined,
        // method: 'POST',
        //callback: connectTeams,
        form: {
          api_key: {
            label: 'API Key',
            type: 'text',
            required: true,
            //value: data.name,
            errorMessage: 'Please enter an API Key'
          },
          webhook_signing_secret: {
            label: 'Signing Secret',
            type: 'password',
            required: true,
            //value: data.name,
            errorMessage: 'Please enter your webhook signing secret',
          }
        },
      }
    },
    {
      name: 'FreshBooks',
      short_name: 'freshbooks',
      type: 'data',
      icon: '/images/freshbooks.svg',
      coming_soon: true
    },
    {
      name: 'Dynamics 365',
      short_name: 'dynamics',
      type: 'data',
      icon: '/images/d365.png',
      coming_soon: true,
      settings: {
        title: 'Setup Connection',
        buttonText: 'Connect',
        url: undefined,
        // method: 'POST',
        //callback: connectTeams,
        form: {
          tenant_id: {
            label: 'Tenant ID',
            type: 'text',
            required: true,
            //value: data.name,
            errorMessage: 'Please enter the org tenant ID from Dynamics 365'
          },
        },
      }
    },
    {
      name: 'ADP',
      short_name: 'adp_run',
      type: 'data',
      icon: '/images/adp.png',
      coming_soon: true,
      prefix: 'Company'
    },
    // {
    //   name: 'Dext',
    //   short_name: 'dext',
    //   type: 'data',
    //   icon: '/images/dext.png',
    //   coming_soon: true,
    //   prefix: 'Company'
    // },
    {
      name: 'BILL',
      short_name: 'bill',
      type: 'data',
      icon: '/images/bill_com.png',
      coming_soon: true,
      prefix: 'Company'
    },
    {
      name: 'Brex',
      short_name: 'brex',
      type: 'data',
      icon: '/images/brex.jpeg',
      coming_soon: true,
      prefix: 'Company'
    },
    {
      name: 'Slack',
      short_name: 'slack',
      type: 'notification',
      icon: '/images/slack.png',
      coming_soon: false,
      prefix: 'Team'
    },
    {
      name: 'MS Teams',
      short_name: 'teams',
      type: 'notification',
      icon: '/images/msteams.png',
      coming_soon: false,
      prefix: 'Team',
      edit_settings: true,
      settings: {
        title: 'Setup Connection',
        buttonText: 'Connect',
        url: undefined,
        // method: 'POST',
        //callback: connectTeams,
        form: {
          name: {
            label: 'Name',
            type: 'text',
            required: true,
            //value: data.name,
            errorMessage: 'Please enter a Team name'
          },
          webhook_url: {
            label: 'Webhook Url',
            type: 'text',
            required: true,
            //value: data.webhook_url,
            errorMessage: 'Please enter a Url where to send messages'
          }
        },
      }
    },
    {
      name: 'Asana',
      short_name: 'asana',
      type: 'pm',
      icon: '/images/asana.png',
      coming_soon: false
    },
    {
      name: 'Karbon',
      short_name: 'karbon',
      type: 'pm',
      icon: '/images/karbon.png',
      coming_soon: false,
      edit_settings: true,
      settings: {
        title: 'Setup Connection',
        buttonText: 'Connect',
        url: undefined,
        // method: 'POST',
        //callback: connectTeams,
        form: {
          access_token: {
            label: 'Token',
            type: 'text',
            required: true,
            //value: data.name,
            errorMessage: 'Please enter the token given from Karbon'
          },
          org_id: {
            label: 'Organization ID',
            type: 'text',
            required: true,
            //value: data.webhook_url,
          },
          work_template_id: {
            label: 'Work Template Key',
            type: 'text',
            required: false,
            //value: data.webhook_url,
          },
          assignee_email: {
            label: 'Assignee Email',
            type: 'email',
            required: false,
            //value: data.webhook_url,
          }
        },
      }
    },
    {
      name: 'Canopy',
      short_name: 'canopy',
      type: 'pm',
      icon: '/images/canopy.png',
      coming_soon: true
    },
    // {
    //   name: 'Liscio',
    //   short_name: 'liscio',
    //   type: 'pm',
    //   icon: '/images/liscio.png',
    //   coming_soon: true
    // },
    // {
    //   name: 'Intuit ProConnect',
    //   short_name: 'intuit_pc',
    //   type: 'pm',
    //   icon: '/images/proconnect-tax.png',
    //   coming_soon: true
    // },
    {
      name: 'HubSpot',
      short_name: 'hubspot',
      type: 'crm',
      icon: '/images/hubspot.png',
      coming_soon: false,
      prefix: 'Organization',
      description: "Connecting HubSpot enables real-time data monitoring and data warehousing. LedgerView will begin by retrieving the last 30 days of details and process it according to the rules in place. It will flag all rules violations and show them in the LedgerView dashboard. Once completed, LedgerView will be alerted to any changes within HubSpot and, in real-time, will process these changes."
    
    }
  ]

  // function webSocketMsg(data) {
  //   console.log("app_connection.webSocketMsg: " + data);
  //   // viewContext.notification.show(data, 'success', true, 'toast');
  //   alert(data)

  // }
  
  // useEffect(() => {
  //   // Assuming userId is passed as a prop to this component
  //   // console.log(JSON.stringify(authContext))
    
  //   //authContext.createWebSocket(webSocketMsg);
  //   authContext.wsMessageHandler((data) => (data) => webSocketMsg(data));

  // }, []); 
  
  const findApp = (name) => {
    var ret;
    apps.map((app) => {
      console.log(name)
      if((name === app.short_name) || (name === app.name)){
        ret = app;
      }
    });
    return ret;
  }

  const findAppByConnectionId = (id) => {
    var ret;
    connections.map((app) => {
      // console.log('conns - ' + app.id)
      if(id === app.id){
        // console.log('conn found - ' + id)
        ret = findApp(app.name);
      }
    });
    // console.log(JSON.stringify(ret))
    return ret;
  }

  const updateConnections = (list) => {
    if(list?.data?.length) {
      var newList = [];
      //console.log('list.data.length - ' + list.data.length)
      list.data.map((item) => {
        var app = findApp(item.app);
        console.log(JSON.stringify(app))
        item.icon = app.icon;
        item.short_name = item.app;
        item.org_name = item.name;
        item.name = app.name;
        item.prefix = app.prefix;
        item.edit_settings = app.edit_settings;
        newList.push(item)
      })
      setConnections(newList);
    }

  }
  useEffect(() => {
    updateConnections(list);
  }, [list.data]);

  var config = {
    authUri: '',
    redirectUri: '{{{ redirect_uri }}}'
  }

  const startConnectionFlow = (short_name) => {
    if(short_name === 'qbo') {
      connectQbo();
      // axios.get('/api/app_connection/connect/' + short_name);
    }
    else if(short_name === 'xero') {
      connectXero();
    }
    else if(short_name === 'freshbooks') {
      connectFreshBooks();
    }
    else if(short_name === 'slack') {
      connectSlack();
    }
    else if(short_name === 'asana') {
      connectAsana();
    }
    else if(short_name === 'hubspot') {
      connectHubspot();
    }
    // else if(short_name === 'dynamics') {
    //   connectDynamics();
    // }
  }

  const connectApp = (short_name, conn_id) => {

    var settings = {
      ...findApp(short_name),
      active_conn: {
        ...findConnection(findApp(short_name))
      }
    }
    console.log(settings);
    setActiveSettings(settings);
    setShowSettingsPanel(short_name);
  }

  function connectStripe(data) {
    console.log("connectTeams - " + JSON.stringify(data))
    const appConnectionReq = {
      app: 'stripe',
      co_name: '',
      name: 'Stripe',
      is_sandbox: false,
      is_notification_conn: false,
      unique_id: 'stripe_' + user.data.default_account + '_',
      settings: {
          //access_token: accessToken,
          api_key: data.api_key.value
      }
    };
    axios.post('/api/app_connection', appConnectionReq);

    // app_connectionController.create(appConnectionReq, res);
  }

  function connectTeams(data) {
    console.log("connectTeams - " + JSON.stringify(data))
    const appConnectionReq = {
      app: 'teams',
      co_name: '',
      name: data.name,
      is_sandbox: false,
      is_notification_conn: true,
      unique_id: 'teams_' + user.data.default_account + '_',
      settings: {
          //access_token: accessToken,
          webhook_url: data.webhook_url
      }
    };
    axios.post('/api/app_connection', appConnectionReq);

    // app_connectionController.create(appConnectionReq, res);
  }

  function connectKarbon(data) {
    console.log("connectKarbon - " + JSON.stringify(data))
    const appConnectionReq = {
      app: 'karbon',
      co_name: '',
      name: 'Karbon',
      is_sandbox: false,
      is_notification_conn: false,
      data_src_id: data.org_id.value,
      unique_id: 'karbon_' + user.data.default_account + '_',
      settings: {
          access_token: data.access_token.value,
          work_template_id: data.work_template_id.value,
          assignee_email: data.assignee_email.value
      }
    };
    axios.post('/api/app_connection', appConnectionReq).then(function (response) {

      // const karbonWebhook = {
      //     "access_token": data.access_token.value
      // }
      // axios.post('/api/app_connection/karbon/webhook', karbonWebhook);

    });
  }

  const connectAsana = () => {

    window.location.href = '/asana/authUri?accountId=' + user.data.default_account;
  }

  const connectQbo = () => {

    window.location.href = '/authUri?accountId=' + user.data.default_account;
}

const connectXero = () => {


  window.location.href = '/xero/authUri?accountId=' + user.data.default_account;

  // axios.get('http://localhost:3001/xero/connect').then((uri) => {
  //   config.authUri = uri
  //     // Launch Popup
  //     var parameters = "location=1,width=800,height=650";
      
  //     console.log("Yhe config authUri is :"+JSON.stringify(config.authUri));
  //     var win = window.open(config.authUri.data, 'connectPopup', parameters);
  //     var pollOAuth = window.setInterval(function () {
  //         try {
  //             console.log("The window URL is :" + win.document.URL);
  //             if (win.document.URL.indexOf("code") != -1) {
  //                 window.clearInterval(pollOAuth);
  //                 win.close();
  //                 //location.reload();
  //             }
  //         } catch (e) {
  //             console.log(e)
  //         }
  //     }, 100);

  // });
}

const connectFreshBooks = () => {

  window.location.href = '/freshbooks/authUri?accountId=' + user.data.default_account;
}

const connectHubspot = () => {


  window.location.href = '/hubspot/authUri?accountId=' + user.data.default_account;
}

const connectDynamics = (data) => {

  window.location.href = '/dynamics/authUri?accountId=' + user.data.default_account + '&tenant_id=' + data.tenant_id.value;
}

const connectSlack = () => {
  window.location.href="https://slack.com/oauth/v2/authorize?scope=channels%3Aread%2Cincoming-webhook%2Cchat%3Awrite&redirect_uri=https%3A%2F%2Fhindsight.ngrok.io%2Fslack%2Fcallback&client_id=2087225461440.4042097929767&state=" + user.data.default_account;
}

const disconnect = (id) => {
  viewContext.modal.show({

    title: 'Disconnect Application?',
    form: {},
    buttonText: 'Disconnect',
    url: '/api/app_connection/disconnect/' + id,
    method: 'PATCH',
    destructive: false,
    text: 'Are you sure you want to disconnect this application? ',

  }, async () => {
    const list = await axios.get('/api/app_connection');
    // alert(JSON.stringify(list.data));
    updateConnections(list.data)
  });
  // axios.patch('/api/app_connection/disconnect/' + id);
}

function editSettings(conn){
  
  var app = findAppByConnectionId(conn.id);
  // console.log(JSON.stringify(app.settings))
  viewContext.modal.show(app.settings, (form, res) => {
    console.log('showSettings closing - ' + JSON.stringify(form))
    connectTeams(form);
    viewContext.modal.hide();
  });
}

function showSettings(data, app){
  
  alert(JSON.stringify(data))
  // viewContext.modal.show(app.settings, (form, res) => {
  console.log(JSON.stringify(data));

    if(app === "teams"){
      connectTeams(data);
    }
    else if(app === "karbon"){
      connectKarbon(data);
    }
    else if(app === "stripe"){
      connectStripe(data);
    }
    else if(app === "dynamics"){
      connectDynamics(data);
    }
    
  //   viewContext.modal.hide();
  // });
}

function findConnection(item) {
  var activeConn = undefined;
  if(connections === undefined) {
    return activeConn;
  }
  connections.map((conn) => {
    if(conn.short_name === item.short_name) {
      activeConn = conn;
    }
  })

  return activeConn;
}

  return(
    <Fragment>
      {/* <script>
        var oauth = new OAuthCode(config);

        
      </script> */}


  {/* <nav className='subnav'>

      <NavLink exact to='/app/app_connection' onClick={() => setShowLib(false)} activeClassName={showLib ? '' : 'active'}>Active Connections</NavLink>
      <NavLink exact to='/app/app_connection' onClick={() => setShowLib(true)} activeClassName={showLib ? 'active' : ''}>Connection Library</NavLink>
  </nav> */}
{/* 
{showLib == false &&
  <Grid cols='4'>
    { connections?.length ? connections.map((item) => {
      
          return(
            <Card>
              <img style={{height: 40, width: 40}} src={item.icon} />
              <h2>{item.name}</h2>
              
              <div style={{minHeight: 30}}>{item.prefix}{ item.prefix ? ':' : ''} {item.org_name}</div>
              <div style={{paddingTop: 30}}>
                <Button color='#707070' icon='trash-2' action={() => disconnect(item.id)} />
                {
                  item.edit_settings == true &&
                  <Button color='#707070' icon='settings' action={() => editSettings(item)} />
                }
              </div>
            </Card>
          );
        }) : <div>No items to display</div>
      }
  </Grid>
} */}


  {showLib === true &&
  <>
  {appCategories.map((cat) => {
    return(
      <>
  
  <h1 style={{paddingTop: 40, paddingBottom: 10}}>{cat.name}</h1>
  
  <Grid cols="3">
  { apps.filter(app => app.type===cat.code).map((item) => {
    var activeConn = findConnection(item)
    return(
      
      <Card highlight={activeConn !== undefined}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <img style={{height: 40, width: 40}} src={item.icon} alt="" />
          <div style={{paddingTop: '6px', fontSize: '20px', fontWeight: 'bold', paddingLeft: '20px', width: '70%'}}>{item.name}</div>
          
          {activeConn === undefined ? 
          <div style={{minHeight: 30, paddingTop: '10px', width: '120px'}}>
            {item.coming_soon ? 'Coming soon' : 
              // item.short_name === 'qbo' ?
              // <a href="#" onClick={() => connectApp(item.short_name)}><img style={{height: 30}} src='/images/C2QB_green_btn_short_default_2x.png' alt="" /></a> :
              <a href="#" onClick={() => connectApp(item.short_name)}>Connect</a>}
          </div>
          :
          <div style={{minHeight: 30, paddingTop: '10px'}}>
            <a href="#" onClick={() => connectApp(item.short_name, activeConn.id)}>Settings</a>
            {/* {
              activeConn.edit_settings === true &&
              <Button color='#707070' icon='settings' action={() => editSettings(activeConn)} />
            } */}
          </div>
        }
        </div>
      </Card>
    )})}
</Grid>
</>
    );

})
}

  </>
      }
      

      {showSettingsPanel &&
      <Sidebar visible={showSettingsPanel !== null} position="right" onHide={() => setShowSettingsPanel(null)} style={{width: '40%'}}>
          
          <div style={{display: 'flex', flexDirection: 'row', paddingBottom: "20px"}}>
            <img style={{height: 40, width: 40}} src={activeSettings?.icon} alt="" />
            <div style={{paddingTop: '6px', fontSize: '20px', fontWeight: 'bold', paddingLeft: '20px', width: '70%'}}>{activeSettings?.name}</div>
          </div>

          <Badge color={activeSettings?.active_conn?.is_active === true ? "green" : "orange"} text={activeSettings?.active_conn?.is_active ? "Connected" : "Not Connected"} />
          {activeSettings?.active_conn?.is_active &&
          <>
            <table style={{marginTop: "10px", marginBottom: "10px"}}>
              <tr style={{height: "30px"}}>
                <td>Connected Since: </td>
                <td>{new Date(activeSettings.active_conn.created_at).toLocaleDateString("en-US", {hour12: true}) + " " + new Date(activeSettings.active_conn.created_at).toLocaleTimeString("en-US", {hour12: true})}</td>
              </tr>

              <tr>
                <td>Last Activity:</td>
                <td>{new Date(activeSettings.active_conn.updated_at).toLocaleDateString("en-US", {hour12: true}) + " " + new Date(activeSettings.active_conn.updated_at).toLocaleTimeString("en-US", {hour12: true})}</td>
              </tr>
            </table>
            
            
            
            <Button action={() => disconnectConfirm ? setDisconnectConfirm(null) : setDisconnectConfirm(activeSettings.active_conn.id)} small text="Disconnect" color="blue"></Button>
            
            {disconnectConfirm &&
            <>
            <Card highlight>
            <h2>Are you sure you want to disconnect {activeSettings?.name}?</h2>
            <Form 
              title={'Disconnect Application'}
              buttonText={'Yes, Disconnect'}
              cancel={() => setDisconnectConfirm(null)}
              cancelButton="No, Cancel"
              url={'/api/app_connection/disconnect/' + disconnectConfirm}
              method={'PATCH'}
              destructive={false}
              text={'Are you sure you want to disconnect this application? '}
              />
              </Card>
            </>
            }
              
              

            </>
          }

          {activeSettings.settings?.form ?
            <>
              <div style={{paddingTop: '30px'}}>
                <h3>Settings</h3>
              </div>
              <Form 
                data={activeSettings.settings?.form}
                buttonText={activeSettings?.active_conn?.is_active ? "Update" : "Connect"}
                submit={(data) => showSettings(data, activeSettings.short_name)}
              >
              </Form>
            </>
          
          : 

          ((activeSettings?.active_conn === undefined) || (JSON.stringify(activeSettings?.active_conn) === '{}')) &&
            <>
              <div style={{paddingTop: '20px'}}></div>
              <a href="#" onClick={() => startConnectionFlow(activeSettings.short_name)}>{activeSettings.short_name === 'qbo' ? <img style={{height: 30}} src='/images/C2QB_green_btn_short_default_2x.png' alt="" /> : "Connect"}</a>
            </>
            
          }


          {activeSettings?.description &&
          <p>
              <h3>Details</h3>
              <Markdown>{activeSettings?.description}</Markdown>
              
          </p>
          }
      </Sidebar>
      }

    </Fragment>

  );
}
