import React, { useState, useContext, useEffect } from 'react';
import { AuthContext, Card, useAPI } from 'components/lib';
import { Table } from 'components/lib';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { NavLink } from "react-router-dom";


export function Cleanup(props){
  const authContext = useContext(AuthContext);

  const [recordList, setRecordList] = useState([]);
  const [dataTypeList, setDataTypeList] = useState([]);
  const [currentDataType, setCurrentDataType] = useState(null);

  var records = useAPI('/api/appData/recordsToClean/' + authContext.user.appConnection)
  var dataTypes = useAPI('/api/appData/dataTypes/')

  const updateRecordList = (records) => {
    //alert(JSON.stringify(records))
    if(records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];

      records.map((item) => {
        let id = item.id;
        //let data = item.data();
        recList.push({
          id: id,
          checked: false,
          type: item.record_type,
          name: item.name,
          Last_Updated: item.last_updated_at,
          monitor: item.rule_name,
          // issue: item.short_description,
          status: item.status,
          //recDetails: data.recDetails
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    updateRecordList(records?.data)

  }, [records.data]);
  
  useEffect(() => {
    if(dataTypes?.data?.length) {
      
      let recList = [];

      dataTypes.data.map((item) => {
        //let data = item.data();
        recList.push({
          value: item.name,
          label: item.name
        });
      });

      setDataTypeList(recList);

    }

  }, [dataTypes.data]);

  const setActiveRecord = (rec, index) => {
    window.location = 'details/'+rec.id;
  };
  
  // const selectDataType = (val) => {
  //   setCurrentDataType(val)
    
  // }

  return (
    <>
    <nav className='subnav'>
    <NavLink exact to='/app/cleanup/index' activeClassName='active'>Clean-Up</NavLink>
    <NavLink exact to='/app/activity/index' activeClassName='active'>All Activity</NavLink>
    </nav>

    
      
<>

      <Card>
        <div style={{display: 'flex', paddingBottom: 20}}>
          {/* <div style={{marginTop: '16px', paddingRight: '10px'}}>Filter:</div> */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {/* <div style={{zIndex:1000}}>
            <Select
                placeholder="Data Types"
                onChange={(item) => {selectDataType(item.value)}}
                options={dataTypeList}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (base) => ({...base, height: 53, minHeight: 53, marginRight: 20, marginLeft: 20 })
                }}
            />
            </div> */}
        

          {/* <Button
            text='Find'
            action={ findRecs }
            params={{ id: 'btnFind' }}
          /> */}

         
        </LocalizationProvider>
        </div>

        
      
      <Table
        search
        data={ recordList }
        checkItems="monitor"
        loading={ recordList?.length === 0 }
        hide={'id,checked'}
        hyperlink={{
          col: 'name',
          //url: '/app/DataHistory',
          //callback: loadCompany
        }}
        badge={{ col: 'status', color: 'blue', condition: [

          { value: 'warning', color: 'yellow' },
          { value: 'error', color: 'red' }
        
        ]}}
        actions={{ 
          hyperlink: setActiveRecord,
          edit: setActiveRecord, 
          // selectRecord: selectRecord,
          //view: setActiveRecord, //'/dashboard' // will load this url + /rowID  
           
        }}
      >
      </Table>
      </Card>
      </>
    
    </>
  );
}
